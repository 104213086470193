import axios from "axios";
import { getUserPlatform, messageBridge } from "~/scripts/utils";
import { getReturnURL } from "../auth";

export const callbackFacebook = (response) => {
  const return_to = getReturnURL();

  let payload = {
    ...response.authResponse,
  };

  if (return_to) {
    const url = new URL(return_to);

    if (
      url &&
      !["/account/login", "/account/register"].includes(url.pahtname)
    ) {
      payload = {
        ...payload,
        return_to,
      };
    }
  }

  axios
    .post(`https://api.wooltariusa.com/auth/facebook/callback`, payload, {
      headers: {
        "X-Shopify-Locale": window.Shopify.locale,
      },
    })
    .then((response) => {
      const { token, code, customer } = response.data;

      if (code === "auth/new-customer") {
        window?.fbq("track", "CompleteRegistration");
        window?.gtag("event", "sign_up", { method: "facebook" });
        messageBridge.postMessage({
          type: "analytics",
          payload: {
            event: "signup",
            properties: {
              method: "facebook",
            },
          },
        });
        if (customer) {
          window?.mixpanel.alias(`${customer.id}`);
          window?.mixpanel.track("signup", {
            $first_name: customer.first_name,
            $last_name: customer.last_name,
            $email: customer.email,
            referral: `facebook`,
            platform: `${getUserPlatform()}`,
          });
          window?.twq("event", "tw-od7mn-od7mv", {
            status: "completed",
            description: "facebook",
            email_address: customer?.email,
          });

          // Taboola Pixel
          // window?._tfa.push({ notify: "event", name: "signup", id: 1564798 });

          window?.ttq.track("CompleteRegistration", {
            method: "facebook",
          });
        }
      }

      if (token) {
        window.location = `${window.location.origin}/account/login/multipass/${token}`;
      }
    })
    .catch((error) => console.log(error));
};

export const rerequestPermission = () => {
  window.FB.login(statusChangeCallback, {
    auth_type: "rerequest",
    scope: "public_profile,email",
  });
};

export const statusChangeCallback = (response) => {
  // The response object is returned with a status field that lets the
  // app know the current login status of the person.
  // Full docs on the response object can be found in the documentation
  // for FB.getLoginStatus().
  if (response.status === "connected") {
    // Logged into your app and Facebook.
    window.FB.api("/me/permissions", (resPermission) => {
      const emailPermission = resPermission.data.filter(
        (item) => item.permission == "email" && item.status == "granted"
      );

      if (emailPermission.length === 0) {
        const isConfirm = window.confirm(
          "With your email, we can register shopify account."
        );

        if (isConfirm) {
          rerequestPermission();
        }
      } else {
        callbackFacebook(response);
      }
    });
    // testAPI();
  } else if (response.status === "not_authorized") {
    // The person is logged into Facebook, but not your app.
    // document.getElementById("status").innerHTML =
    //   "Please log " + "into this app.";
  } else {
    // The person is not logged into Facebook, so we're not sure if
    // they are logged into this app or not.
    // document.getElementById("status").innerHTML =
    //   "Please log " + "into Facebook.";
  }
};

export const checkLoginState = () => {
  window.FB.getLoginStatus((response) => {
    statusChangeCallback(response);
  });
};

export default { statusChangeCallback, checkLoginState };
