import MobileDetect from "./MobileDetect";

const heartbeat = (data) => {
  const { customerId, email } = data;
  postMessage({
    type: "heartbeat",
    payload: {
      ...data,
      customerId,
      email,
    },
  });
};

const share = (method, properties) => {
  // METHOD: 'universal', 'kakao', 'facebook', 'twitter', 'sms'
  postMessage({
    type: "share",
    payload: {
      method,
      properties,
    },
  });
};

const track = (event, properties) => {
  postMessage({
    type: "analytics",
    payload: {
      event,
      properties,
    },
  });
};

const vibration = () => {
  postMessage({
    type: "vibration",
    payload: true,
  });
};

const postMessage = (message) => {
  if (!MobileDetect.isApp) return;

  let jsonStr = JSON.stringify(message);

  if (MobileDetect.isIOS) {
    window?.webkit?.messageHandlers?.wooltariMobileAppInterface?.postMessage(
      jsonStr,
      "*"
    );
  } else if (MobileDetect.isAndroid) {
    window?.wooltariMobileAppInterface?.postMessage(jsonStr);
  }

  if (window?.ReactNativeWebView) {
    window?.ReactNativeWebView.postMessage(jsonStr);
  }
};

export default { postMessage, vibration, track, share, heartbeat };
