import Alpine from "alpinejs";
import { copyToClipboard } from "./utils";

export const initShare = () => {
  start();
};

const start = () => {
  Alpine.store("share", {
    url: null,
    props: null,
    openModal(url, props) {
      this.url = url;
      this.props = props;

      document.body.classList.add("overflow-hidden");
    },
    closeModal() {
      this.url = null;
      this.props = null;

      document.body.classList.remove("overflow-hidden");
    },
    copy(url, type = "url", props) {
      if (type === "facebook") {
        const data = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
        window.open(
          data,
          "",
          "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=400,width=600"
        );
      } else if (type === "twitter") {
        const data = `https://twitter.com/share?url=${url}`;
        window.open(
          data,
          "",
          "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=800,width=600"
        );
      } else if (type === "kakao") {
        window?.Kakao.Share.sendDefault({
          objectType: "feed",
          content: {
            title: props.title,
            description: props.description,
            imageUrl: `https:${props.image}`,
            link: {
              mobileWebUrl: url,
              webUrl: url,
            },
          },
        });
      } else {
        Alpine.store("toast").create(window.$t("share.copied"));
        copyToClipboard(url);
      }
    },
  });
};
