import MobileDetect from "./MobileDetect";
import copyToClipboard from "./copyToClipboard";
import messageBridge from "./messageBridge";

function getUserPlatform() {
  if (MobileDetect.isApp) {
    return MobileDetect.isIOS ? "ios-app" : "android-app";
  } else if (MobileDetect.isIOS) {
    return "ios-web";
  } else if (MobileDetect.isAndroid) {
    return "android-web";
  }

  return "web";
}

export { copyToClipboard, messageBridge, getUserPlatform, MobileDetect };
