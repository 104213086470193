import Alpine from "alpinejs";
import { isEmpty, trim, map, get, debounce } from "lodash-es";
import axios from "axios";
import { getUserPlatform } from "./utils";

export const initSearch = () => {
  start();
};

const fetchProduct = async (handle) => {
  try {
    // console.log("handle", handle, window.Shopify.locale);
    let url = `${handle}/?view=card`;
    // 중요: 나중에 window.Shopify.routes.root 로 대체하면 됨
    if (window.Shopify.locale === "en") {
      url = `/${window.Shopify.locale}${url}`;
    }

    const response = await axios.get(url);

    if (response.status === 200) {
      return {
        handle: handle,
        data: response.data,
      };
    }
  } catch (error) {
    return {
      handle: handle,
      data: null,
    };
  }
};

const getQuery = () => {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  return params?.query;
};

// Start our component
const start = () => {
  Alpine.store("searchbar", {
    show: false,
    isSearching: false,
    isFocused: false,
    query: null,
    keywoardForwarding: null,
    data: null,
    setKeywordForwarding(keywords) {
      this.keywoardForwarding = keywords;
    },
    view(query) {
      window.location.href =
        window.Shopify.routes.root + "pages/search?query=" + query;
    },
    closeSearchBar() {
      Alpine.store("searchbar").show = false;
      Alpine.store("searchbar").isFocused = false;
      Alpine.store("searchbar").isSearching = false;
      document.body.classList.remove("prevent-scrolling");
    },
    resetSearchBar() {
      Alpine.store("searchbar").show = false;
      Alpine.store("searchbar").isFocused = false;
      Alpine.store("searchbar").isSearching = false;
      document.body.classList.remove("prevent-scrolling");
      const query = getQuery();

      if (query !== Alpine.store("searchbar").query) {
        Alpine.store("searchbar").query = query;
      }

      if (!query) {
        Alpine.store("searchbar").query = null;
        Alpine.store("searchbar").data = null;
      }
      // this.focused = false;
    },
    setFocused(value) {
      if (value) {
        this.isFocused = true;
        // this.show = true;
      } else {
        this.isFocused = false;
        // this.show = false;
      }
      // this.focused = value;
    },
    init() {
      this.query = getQuery();

      // window.addEventListener("resize", debounce(this.resetSearchBar, 500));
    },
  });

  Alpine.effect(() => {
    const searchbarStore = Alpine.store("searchbar");
    const query = searchbarStore.query;
    const isFocused = searchbarStore.isFocused;
    const show = searchbarStore.show;

    if (!isEmpty(trim(query))) {
      if (isFocused) {
        searchbarStore.show = true;
        if (window.innerWidth <= 1024) {
          document.body.classList.add("prevent-scrolling");
        }
      }
    } else {
      searchbarStore.show = false;

      document.body.classList.remove("prevent-scrolling");
    }
  });

  Alpine.effect(async () => {
    const searchbarStore = Alpine.store("searchbar");
    const data = searchbarStore.data;

    if (data && data?.items) {
      if (data.items.length > 0) {
        searchbarStore.isSearching = true;

        const productHandles = map(data.items, (item) =>
          item.link.replace("/collections/", "")
        );

        const requests = [...productHandles].map((handle) => {
          return fetchProduct(handle);
        });

        const response = await Promise.all(requests);

        const list = [];

        response.forEach((item) => {
          if (item?.data) {
            list.push(item.data);
          }
        });
        document.getElementById("searchbar-results").innerHTML = list.join("");
        // $("#searchbar-results").html(list);
        searchbarStore.isSearching = false;
      }
    }
  });

  Alpine.effect(() => {
    const searchbarStore = Alpine.store("searchbar");
    let query = searchbarStore.query;

    if (searchbarStore?.keywoardForwarding) {
      const forwardedQuery = get(
        searchbarStore,
        `keywoardForwarding.${query}`,
        null
      );

      if (forwardedQuery) {
        query = forwardedQuery;
      }
    }

    if (!isEmpty(trim(query))) {
      axios
        .get(
          `https://searchserverapi.com/getwidgets?api_key=3Z2v8B4Q0d&q=${query}&suggestions=true&items=true&categories=true&facets=false&maxResults=4&suggestionsMaxResults=8&startIndex=0&pages=true`
        )
        .then((response) => {
          if (response.status === 200) {
            searchbarStore.data = response.data;
          }
        });
    }
  });

  Alpine.data("search", () => ({
    isInit: false,
    isFetching: false,
    sort: "relevance-desc",
    isRefetching: false,
    query: null,
    data: null,
    hasMore: false,
    changeSort(event) {
      if (event.target.value) {
        const [sortBy, sortOrder] = event.target.value.split("-");

        this.sort = event.target.value;

        if (this.query) {
          this.search(this.query, 0, { sortBy, sortOrder });
        }
      }
    },
    async init() {
      const query = getQuery();

      if (query && query.length > 0) {
        this.query = query;
        this.search(query);

        this.isInit = true;
      }

      this.$watch("data", (value) => {
        const nextIndex = this.data.startIndex + this.data.itemsPerPage;
        let hasMore = this.hasMore;

        if (
          this.data.totalItems > nextIndex ||
          (this.data.totalItems < nextIndex &&
            nextIndex - this.data.totalItems >= 0)
        ) {
          hasMore = true;
        }

        if (this.data.totalItems === 0) {
          hasMore = false;
        }

        if (
          this.data.startIndex + this.data.currentItemCount >=
          this.data.totalItems
        ) {
          hasMore = false;
        }

        if (
          this.data.startIndex === 0 &&
          this.data.totalItems <= this.data.currentItemCount
        ) {
          hasMore = false;
        }

        this.hasMore = hasMore;
      });
    },
    async refetch() {
      if (this.data && !this.isRefetching && !this.isFetching) {
        const nextIndex = this.data.startIndex + this.data.itemsPerPage;

        if (this.hasMore) {
          this.isRefetching = true;
          this.search(this.query, nextIndex);
        }
      }
    },
    async search(payloadQuery, startIndex = 0) {
      if (this.isFetching) {
        return false;
      }
      if (startIndex === 0) {
        document.getElementById("searchResults").innerHTML = "";
        // $("#searchResults").empty();
      }
      this.isFetching = true;
      let query = payloadQuery;

      const searchbarStore = Alpine.store("searchbar");

      if (searchbarStore?.keywoardForwarding) {
        const forwardedQuery = get(
          searchbarStore,
          `keywoardForwarding.${query}`,
          null
        );

        if (forwardedQuery) {
          query = forwardedQuery;
        }
      }

      const data = await this.fetchSearch(query, startIndex);

      this.data = data;
      this.query = query;

      const productHandles = map(data.items, (item) =>
        item.link.replace("/collections/", "")
      );

      const requests = [...productHandles].map((handle) => {
        return fetchProduct(handle);
      });

      const response = await Promise.all(requests);

      response.forEach((item) => {
        if (item?.data) {
          document
            .querySelector("#searchResults")
            .insertAdjacentHTML("beforeend", `${item.data}`);
          // $("#searchResults").append(item.data);
        }
      });

      this.isFetching = false;
      this.isRefetching = false;
    },
    async fetchSearch(query, startIndex = 0, options) {
      const [defaultSortBy, defaultSortOrder] = this.sort.split("-");
      const sortBy = get(options, "sortBy", defaultSortBy);
      const sortOrder = get(options, "sortOrder", defaultSortOrder);

      try {
        const response = await axios.get(
          `https://searchserverapi.com/getresults?api_key=3Z2v8B4Q0d&q=${query}&startIndex=${startIndex}&sortBy=${sortBy}&sortOrder=${sortOrder}&suggestions=true&items=true&categories=true&facets=true&maxResults=12`
        );

        if (response.status === 200) {
          window?.mixpanel.track("searchanise", {
            status: "success",
            query: query,
            startIndex: startIndex,
            sortBy: sortBy,
            sortOrder: sortOrder,
            totalItems: response?.data?.totalItems,
            platform: `${getUserPlatform()}`,
          });
          return response.data;
        } else {
          window?.mixpanel.track("searchanise", {
            status: "error",
            query: query,
            startIndex: startIndex,
            sortBy: sortBy,
            sortOrder: sortOrder,
            error: "Unknown",
            platform: `${getUserPlatform()}`,
          });
        }
        return null;
      } catch (error) {
        window?.mixpanel.track("searchanise", {
          status: "error",
          query: query,
          startIndex: startIndex,
          sortBy: sortBy,
          sortOrder: sortOrder,
          error: error?.message ? JSON.stringify(error.message) : undefined,
          platform: `${getUserPlatform()}`,
        });
      }

      return null;
    },
  }));
};
