import Alpine from "alpinejs";
import messageBridge from "./utils/messageBridge";

export const initCustomer = () => {
  start();
};

const start = () => {
  Alpine.store("customer", {
    id: null,
    tags: null,
    name: null,
    email: null,
    orders_count: null,
    wishlist: Alpine.$persist({}).using(sessionStorage),
    isInit: Alpine.$persist(false).using(sessionStorage),
    async getToken() {
      try {
        const data = await fetch(
          `${window.Shopify.routes.root}account?view=token`,
          {
            method: "GET",
          }
        ).then((response) => response.text());

        return data;
      } catch (error) {
        return null;
      }
    },
    async setCustomer({ id, tags, name, email, orders_count }) {
      this.id = id;
      this.tags = tags;
      this.name = name;
      this.email = email;
      this.orders_count = orders_count;

      const wishlist = await this.fetchWishlist();

      this.wishlist = wishlist ? wishlist : {};
      this.isInit = true;
      // window.mixpanel.people.set({
      //   wishlist: wishlist ? Object.keys(wishlist) : null,
      // });
    },
    async fetchWishlist() {
      if (window.Shopify.shop == "dev-wooltari.myshopify.com") {
        return null;
      }
      const customerId = this.id;

      if (customerId) {
        const token = await this.getToken();

        const data = await fetch(`https://api.wooltariusa.com/wishlist`, {
          method: "GET",
          credentials: "include",
          headers: {
            Authorization: `${token}`,
          },
        }).then((response) => {
          return response.text();
        });

        if (!data) {
          return null;
        }

        return JSON.parse(data);
      }
    },
    async addWishlist({ title, productId, handle, variantId }) {
      if (window.Shopify.shop == "dev-wooltari.myshopify.com") {
        return null;
      }

      const customer = Alpine.store("customer").id;

      messageBridge.vibration();

      if (customer) {
        if (Object.keys(this.wishlist).length > 99) {
          return Alpine.store("toast").create(
            window.$t("wishlist.max_add_item")
          );
        }

        const nextWishlist = {
          ...this.wishlist,
          [productId]: {
            handle: handle,
            productId: parseInt(productId),
            variantId: parseInt(variantId),
          },
        };
        this.wishlist = nextWishlist;

        const token = await this.getToken();

        try {
          const data = await fetch(`https://api.wooltariusa.com/wishlist`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `${token}`,
            },
            body: JSON.stringify({
              handle: handle,
              productId: parseInt(productId),
              variantId: parseInt(variantId),
            }),
          }).then((response) => response.json());

          window?.mixpanel.track("add_to_wishlist", {
            title: `${title}`,
            handle: `${handle}`,
            product_id: `${productId}`,
            first_available_variant_id: `${variantId}`,
          });
        } catch (error) {
          console.log(error);
        }
      } else {
        return (window.location.href = "/account/login");
      }
    },

    async removeWishlist(productId, { title, handle, variantId }) {
      if (window.Shopify.shop == "dev-wooltari.myshopify.com") {
        return null;
      }

      const customer = Alpine.store("customer").id;

      if (customer) {
        delete this.wishlist[productId];

        const token = await this.getToken();

        try {
          const data = await fetch(
            `https://api.wooltariusa.com/wishlist/${productId}`,
            {
              method: "DELETE",
              headers: {
                Authorization: `${token}`,
              },
            }
          ).then((response) => response.text());
          // .then((error) => {
          //   cons
          //   if (error.response.status === 400) {
          //     return Alpine.store("toast").create(error.response.data);
          //   }
          // });

          if (data) {
            window?.mixpanel.track("remove_wishlist", {
              product_id: `${productId}`,
              title: title ? `${title}` : `${handle}`,
              handle: `${handle}`,
              first_available_variant_id: `${variantId}`,
            });

            return true;
          }
        } catch (error) {
          // console.log(error, error.response);
          // if (error.response.status === 400) {
          //   return Alpine.store("toast").create(error.response.data);
          // }
        }
      } else {
        return (window.location.href = "/account/login");
      }
    },
  });
};
