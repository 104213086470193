import Alpine from "alpinejs";
import $ from "jquery";
import MobileDetect from "./utils/MobileDetect";
import messageBridge from "./utils/messageBridge";

export const initSwell = () => {
  start();
};

const start = () => {
  Alpine.store("swell", {
    isLoading: true,
    customer: null,
    init() {
      $(document).on("swell:setup", () => {
        const customer = window.swellAPI.getCustomerDetails();

        this.customer = customer;
        this.isLoading = false;
      });
    },
    shareReferralLink(method, value) {
      if (this.isLoading) {
        return alert("Please wait for the referral link to load");
      }

      const name = Alpine.store("customer").name;
      const referralUrl = window.swellAPI.getCustomerShareReferralLink();

      if (method === "kakao") {
        const path = `account/register?view=referral&url=${referralUrl}`;
        if (MobileDetect.isApp) {
          messageBridge.share("kakao", {
            url: referralUrl,
            objectType: "custom",
            templateId: 76375,
            templateArgs: {
              TITLE: `${name}`,
              PATH: `${path}`,
            },
          });
        } else {
          window?.Kakao.Share.sendCustom({
            templateId: 76375,
            templateArgs: {
              TITLE: `${name}`,
              PATH: `${path}`,
            },
          });
        }
      } else if (method === "facebook") {
        if (MobileDetect.isApp) {
          let fbTitle = `안녕하세요! No.1 K-Food 이커머스 플랫폼 울타리에서 신규 가입 혜택을 전달 드립니다. 신규 가입 적립금과 함께 $130 value 의 신규회원 혜택 또한 함께 드리오니 지금 가입하시고 울타리를 체험해보세요!`;

          if (window?.Shopify.locale !== "ko") {
            fbTitle = `Hello! We are excited to welcome you to No.1 K-Food e-commerce platform, Wootari. Sign up now to receive new member benefits, including sign-up credits and additional benefits worth $130. Join us today and experience Wootari.`;
          }

          messageBridge.share("facebook", {
            url: referralUrl,
            title: fbTitle,

            // title: `안녕하세요, 미주 최대 프리미엄 농수산식품 커머스 울타리몰에서 사용할 수 있는 ${value} 쿠폰을 보내드립니다. 신규회원 혜택 $130 혜택과 함께 울타리를 체험해보세요! 아래 링크로 추가 ${value}불 받고 시작해보세요 :)`,
          });
        } else {
          const url = `http://www.facebook.com/sharer/sharer.php?u=${referralUrl}`;

          window.open(url, "_blank");
        }
      } else if (method === "twitter") {
        if (MobileDetect.isApp) {
          let xTitle = `안녕하세요! No.1 K-Food 이커머스 플랫폼 울타리에서 신규 가입 혜택을 전달 드립니다. 신규 가입 적립금과 함께 $130 value 의 신규회원 혜택 또한 함께 드리오니 지금 가입하시고 울타리를 체험해보세요!`;

          if (window?.Shopify.locale !== "ko") {
            xTitle = `Hello! We are excited to welcome you to No.1 K-Food e-commerce platform, Wootari. Sign up now to receive new member benefits, including sign-up credits and additional benefits worth $130. Join us today and experience Wootari.`;
          }

          messageBridge.share("twitter", {
            url: referralUrl,
            title: xTitle,
          });
        } else {
          let xShareText = `안녕하세요! No.1 K-Food 이커머스 플랫폼 울타리에서 신규 가입 혜택을 전달 드립니다. 신규 가입 적립금과 함께 $130 value 의 신규회원 혜택 또한 함께 드리오니 지금 가입하시고 울타리를 체험해보세요! 초대 링크:`;

          if (window?.Shopify.locale !== "ko") {
            xShareText = `Hello! We are excited to welcome you to No.1 K-Food e-commerce platform, Wootari. Sign up now to receive new member benefits, including sign-up credits and additional benefits worth $130. Join us today and experience Wootari. `;
          }

          const url = `http://twitter.com/share?text=${xShareText} &url=${referralUrl}&hashtags=wooltarimall,울타리몰`;
          // const url = `http://twitter.com/share?text=안녕하세요, 미주 최대 프리미엄 농수산식품 커머스 울타리몰에서 사용할 수 있는 ${value} 쿠폰을 보내드립니다. 신규회원 혜택 $130 혜택과 함께 울타리를 체험해보세요! 아래 링크로 추가 ${value}불 받고 시작해보세요 :) 초대 링크: &url=${referralUrl}&hashtags=wooltarimall,울타리몰`;

          window.open(url, "_blank");
        }
      } else if (method === "sms") {
        let smsTitle = `안녕하세요! No.1 K-Food 이커머스 플랫폼 울타리에서 신규 가입 혜택을 전달 드립니다. 신규 가입 적립금과 함께 $130 value 의 신규회원 혜택 또한 함께 드리오니 지금 가입하시고 울타리를 체험해보세요! 초대 링크:`;

        if (window?.Shopify.locale !== "ko") {
          smsTitle = `Hello! We are excited to welcome you to No.1 K-Food e-commerce platform, Wootari. Sign up now to receive new member benefits, including sign-up credits and additional benefits worth $130. Join us today and experience Wootari. Invitation link:`;
        }

        if (MobileDetect.isApp) {
          if (MobileDetect.isAndroid) {
            window.location.href = `sms://;?&body=${smsTitle}  ${referralUrl}`;
            // window.location.href = `sms://;?&body=안녕하세요, 미주 최대 프리미엄 농수산식품 커머스 울타리몰에서 사용할 수 있는 ${value} 쿠폰을 보내드립니다. 신규회원 혜택 $130 혜택과 함께 울타리를 체험해보세요! 아래 링크로 추가 ${value}불 받고 시작해보세요 :) 초대 링크:  ${referralUrl}`;
          } else if (MobileDetect.isIOS) {
            window.location.href = `sms:&body=${smsTitle}  ${referralUrl}`;
            // window.location.href = `sms:&body=안녕하세요, 미주 최대 프리미엄 농수산식품 커머스 울타리몰에서 사용할 수 있는 ${value} 쿠폰을 보내드립니다. 신규회원 혜택 $130 혜택과 함께 울타리를 체험해보세요! 아래 링크로 추가 ${value}불 받고 시작해보세요 :) 초대 링크:  ${referralUrl}`;
          }
        } else {
          window.location.href = `sms:&body=${smsTitle}  ${referralUrl}`;
          // window.location.href = `sms:&body=안녕하세요, 미주 최대 프리미엄 농수산식품 커머스 울타리몰에서 사용할 수 있는 ${value} 쿠폰을 보내드립니다. 신규회원 혜택 $130 혜택과 함께 울타리를 체험해보세요! 아래 링크로 추가 ${value}불 받고 시작해보세요 :) 초대 링크:  ${referralUrl}`;
        }
      }
    },
  });
};
