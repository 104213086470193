import Alpine from "alpinejs";
import axios from "axios";
import { statusChangeCallback } from "./utils/facebook";
import { MobileDetect, getUserPlatform, messageBridge } from "~/scripts/utils";

export const getReturnURL = () => {
  const return_url_pathname = new URL(window.location.href).searchParams.get(
    "return_url"
  );

  if (
    return_url_pathname &&
    (return_url_pathname === "/" ||
      return_url_pathname === "/account" ||
      return_url_pathname === "/en/account" ||
      return_url_pathname === "/account/login" ||
      return_url_pathname === "/account/register" ||
      return_url_pathname === "/en/account/login" ||
      return_url_pathname === "/en/account/register")
  ) {
    return `${window.location.origin}${window.Shopify.routes.root}`;
  }

  if (return_url_pathname) {
    return window.location.origin + return_url_pathname;
  }

  if (window.document.referrer) {
    const url = new URL(window.document.referrer);
    const pathname = url?.pathname;

    if (
      pathname &&
      (pathname === "/" ||
        pathname === "/account/login" ||
        pathname === "/account/register" ||
        pathname === "/en/account/login" ||
        pathname === "/en/account/register")
    ) {
      return `${window.location.origin}${window.Shopify.routes.root}`;
    }

    if (
      window.location.host === "wooltariusa.com" ||
      window.location.host === "wooltari.myshopify.com"
    ) {
      return window.document.referrer;
    }
  }

  return `${window.location.origin}${window.Shopify.routes.root}`;
};

window.getReturnURL = getReturnURL;

export const initAuth = () => {
  start();
};

const start = () => {
  Alpine.store("lang", {
    showLanguageModal: false,
    openLanguageModal() {
      this.showLanguageModal = true;
      document.body.classList.add("overflow-hidden");
    },
    closeLanguageModal() {
      this.showLanguageModal = false;
      document.body.classList.remove("overflow-hidden");
    },
  });
  Alpine.store("auth", {
    init: false,
    login(method) {
      const return_to = getReturnURL();

      if (method === "facebook") {
        if (MobileDetect.isApp) {
          messageBridge.postMessage({
            type: "login",
            payload: {
              method: "facebook",
              return_to: return_to,
            },
          });
        } else {
          window.FB.login(
            function (response) {
              statusChangeCallback(response);
            },
            { scope: "public_profile,email" }
          );
        }
      } else if (method === "apple") {
        if (MobileDetect.isApp && MobileDetect.wooltariiOSVer >= 1) {
          messageBridge.postMessage({
            type: "login",
            payload: {
              method: "apple",
              return_to: return_to,
            },
          });

          return;
        }

        window.AppleID.auth.signIn();
      } else if (method === "google") {
        if (MobileDetect.isApp) {
          messageBridge.postMessage({
            type: "login",
            payload: {
              method: "google",
              return_to: return_to,
            },
          });
        }
      }
    },
  });

  document.addEventListener("AppleIDSignInOnSuccess", (event) => {
    const { detail } = event;
    const return_to = getReturnURL();

    if (detail?.authorization) {
      let payload = {
        ...detail,
      };

      if (return_to) {
        const url = new URL(return_to);

        if (
          url &&
          !["/account/login", "/account/register"].includes(url.pahtname)
        ) {
          Object.assign(payload, { return_to });
        }
      }

      axios
        .post(`https://api.wooltariusa.com/auth/apple/callback`, payload, {
          headers: {
            "X-Shopify-Locale": window.Shopify.locale,
          },
        })
        .then((response) => {
          const { token, code, customer } = response.data;

          if (code === "auth/new-customer") {
            window?.fbq && window.fbq("track", "CompleteRegistration");
            window.gtag("event", "sign_up", { method: "apple" });
            messageBridge.postMessage({
              type: "analytics",
              payload: {
                event: "signup",
                properties: {
                  method: "apple",
                },
              },
            });

            if (customer) {
              window?.mixpanel.alias(`${customer.id}`);
              window?.mixpanel.track("signup", {
                $first_name: customer.first_name,
                $last_name: customer.last_name,
                $email: customer.email,
                referral: `apple`,
                platform: `${getUserPlatform()}`,
              });
              window.twq("event", "tw-od7mn-od7mv", {
                status: "completed",
                description: "apple",
                email_address: customer?.email,
              });
            }

            window?.ttq.track("CompleteRegistration", {
              method: "apple",
            });

            // Taboola Pixel
            // window?._tfa.push({ notify: "event", name: "signup", id: 1564798 });
          }

          if (token) {
            window.location = `${window.location.origin}/account/login/multipass/${token}`;
          }
        })
        .catch((error) => console.log(error));
    }
  });

  document.addEventListener("AppleIDSignInOnFailure", (event) => {
    console.log(event);
  });
};
