import "raty-js/lib/jquery.raty.js";
import App from "./scripts/App.js";
import { initCustomer } from "./scripts/customer.js";
import { initCart } from "./scripts/cart.js";
import { initProduct } from "./scripts/product.js";
import { initSwell } from "./scripts/swell.js";
import { initReview } from "./scripts/review.js";
import { initShare } from "./scripts/share.js";
import { initSearch } from "./scripts/search.js";
import { initAuth } from "./scripts/auth.js";
import messageBridge from "./scripts/utils/messageBridge.js";
import MobileDetect from "./scripts/utils/MobileDetect.js";
import Vimeo from "@vimeo/player";
import { getUserPlatform } from "./scripts/utils/index.js";
import {
  get,
  debounce,
  includes,
  isNull,
  isEmpty,
  map,
  sortBy,
  find,
  last,
} from "lodash-es";

function init() {
  App(() => {
    initAuth();
    initCustomer();
    initCart();
    initProduct();
    initReview();
    initSwell();
    initShare();
    initSearch();

    document.addEventListener("DOMContentLoaded", function () {
      const parentElement = document;

      // Create a new MutationObserver instance
      const observer = new MutationObserver(function (mutationsList, observer) {
        // Iterate over the list of mutations
        for (let mutation of mutationsList) {
          // Check if the mutation is a new node being added to the DOM
          // JudgeMe Reply Show and Hide
          if (mutation.target.classList.contains("jdgm-rev__replier-wrapper")) {
            mutation.target.addEventListener("click", function (event) {
              event.preventDefault();
              mutation.target.parentNode.classList.toggle("active");
            });
          }
          // End JudgeMe Reply Show and Hide

          // BackInStock Submit Tracking
          if (mutation.type === "childList" && mutation.addedNodes.length > 0) {
            for (let child of mutation.addedNodes) {
              // If child element id is BIS_frame
              if (child && child?.id && child.id === "BIS_frame") {
                const iframe = child?.contentDocument;

                if (iframe) {
                  const submitBtn = iframe.querySelector("#submit-btn");

                  if (submitBtn) {
                    submitBtn.addEventListener("click", () => {
                      const productName =
                        iframe.querySelector(".product-name").innerHTML;
                      const variantId = iframe.querySelector(
                        ".single_variant_product"
                      ).value;
                      const email =
                        iframe.querySelector("input[type=email]").value;

                      if (email && email.length > 0) {
                        const backInStockProperties = {
                          variant_id: variantId,
                          title: productName,
                          email: email,
                          platform: `${getUserPlatform()}`,
                        };

                        window?.mixpanel.track(
                          "back_in_stock",
                          backInStockProperties
                        );
                      }
                    });
                  }
                }
              }
            }
            // End BackInStock Submit Tracking
          }
        }
      });

      // Configure the observer to watch for additions of child nodes
      const observerConfig = { childList: true, subtree: true };

      // Start observing the parent element
      observer.observe(parentElement, observerConfig);
    });
  });

  let _ = {
    get: get,
    includes: includes,
    isNull: isNull,
    isEmpty: isEmpty,
    map: map,
    sortBy: sortBy,
    find: find,
    last: last,
    debounce: debounce,
  };

  window.MobileDetect = MobileDetect;
  window.getUserPlatform = getUserPlatform;
  window.Vimeo = Vimeo;
  window._ = _;
  window.messageBridge = messageBridge;
}

export default init;
