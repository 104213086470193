import Alpine from "alpinejs";
import axios from "axios";
import $ from "jquery";
import { v4 as uuidv4 } from "uuid";

export const initReview = () => {
  // First, check if this component has to be initialized
  if (hasOrderPage()) {
    // then, fire it up!
    start();
  }
};

/**
 * Checks if page has autocomplete component
 * @return {Boolean}
 */
const hasOrderPage = () => {
  return document.getElementsByClassName("customers/order").length > 0;
};

// Start our component
const start = () => {
  document.addEventListener("alpine:init", () => {
    Alpine.store("review", {
      loading: true,
      reviews: null,
      available: false,
      setReview(id) {
        this.reviews = {
          ...this.reviews,
          [id]: true,
        };
      },
      async loadReviews($el) {
        if (window.Shopify.shop == "dev-wooltari.myshopify.com") {
          this.loading = false;

          return;
        }

        const orderId = $el.dataset.orderId;
        const token = await Alpine.store("customer").getToken();

        const response = await axios.get(
          `https://api.wooltariusa.com/reviews/${orderId}`,
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );

        if (response.status === 200) {
          const responseData = response.data;
          const { reviews } = responseData;
          this.reviews = reviews;
        }
        this.loading = false;
      },
    });

    Alpine.store("addReview", {
      show: false,
      isSubmitted: false,
      isSubmitting: false,
      files: [],
      email: null,
      name: null,
      product: null,
      orderId: null,
      lineItemId: null,
      message: "",
      submitted: [],
      renderStar() {
        $(".rater").raty({
          half: false,
          starOn: $(".rater").data("on"),
          starOff: $(".rater").data("off"),
        });
      },
      showModal(props) {
        const { email, name, product, orderId, lineItemId } = props;

        this.show = true;
        this.email = email;
        this.name = name;
        this.product = product;
        this.orderId = orderId;
        this.lineItemId = lineItemId;
      },
      closeModal(props) {
        this.show = false;
        this.props = null;
        this.email = null;
        this.name = null;
        this.product = null;
        this.message = "";
        this.files = [];
        this.isSubmitted = false;
        this.isSubmitting = false;
      },
      async submitForm() {
        if (this.isSubmitting) {
          return false;
        }

        const rating = $(".rater").data("raty").score();
        const name = this.name;
        const email = this.email;
        const product = this.product;
        const message = this.message;
        const files = this.files;

        const formData = new FormData();
        this.isSubmitting = true;

        if (files.length > 0) {
          const payloadFiles = files.filter(
            (item) => item.status === "success"
          );

          if (payloadFiles.length > 0) {
            payloadFiles.forEach((item, index) => {
              formData.append(`picture_keys[${index}]`, item.key);
            });
          }

          const uploadingFiles = files.filter(
            (item) => item.status === "loading"
          );

          if (uploadingFiles.length > 0) {
            Alpine.store("toast").create(
              window.$t("reviews.write.uploading_error")
            );

            return false;
          }
        }

        formData.append(
          "url",
          window.Shopify.shop == "dev-wooltari.myshopify.com"
            ? "dev-wooltari.myshopify.com"
            : "wooltari.myshopify.com"
        );
        formData.append(
          "shop_domain",
          window.Shopify.shop == "dev-wooltari.myshopify.com"
            ? "dev-wooltari.myshopify.com"
            : "wooltari.myshopify.com"
        );
        formData.append("platform", "shopify");
        formData.append("reviewer_name_format", "all_initials");
        formData.append("name", name);
        formData.append("email", email);
        formData.append("rating", `${rating}`);
        formData.append("title", "");
        formData.append("body", message);
        formData.append("id", product);

        const token = await Alpine.store("customer").getToken();
        const orderId = this.orderId;

        axios({
          url: "https://judge.me/api/v1/reviews",
          method: "post",
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then(async (response) => {
            const lineItemId = this.lineItemId;
            this.submitted.push(product);

            Alpine.store("review").setReview(lineItemId);

            console.log("Write Review", lineItemId, product);

            // if (window.Shopify.shop == "wooltari.myshopify.com") {
            const addResponse = await axios.post(
              `https://api.wooltariusa.com/reviews/${orderId}`,
              {
                lineItemId,
              },
              {
                headers: {
                  Authorization: `${token}`,
                },
              }
            );

            console.log(addResponse);

            this.isSubmitted = true;
            this.isSubmitting = false;

            return;
            // }
          })
          .catch((error) => {
            console.log("error", error);
            this.isSubmitting = false;
          });
      },
      removeImage(id) {
        if (id) {
          const index = this.files.findIndex((item) => item.id === id);

          if (index !== -1) {
            this.files.splice(index, 1);
          }
        }
      },
      uploadImage(event) {
        const file = event.target.files[0];

        if (!file) {
          return false;
        }
        // console.log('upload image', event);
        axios
          .get(
            "https://judge.me/api/v1/pictures/presigned_data?url=wooltari.myshopify.com&shop_domain=wooltari.myshopify.com&platform=shopify"
          )
          .then((response) => {
            const presigned = response.data;
            const formData = new FormData();

            formData.append("key", "" + presigned.key_prefix + file.name);

            Object.keys(presigned.fields).forEach((item) => {
              formData.append(item, presigned.fields[item]);
            });

            formData.append("file", file);

            const id = uuidv4();

            this.files.push({
              id,
              status: "loading",
              url: null,
              key: null,
            });

            axios({
              url: presigned.url,
              method: "post",
              data: formData,
            }).then((response) => {
              const $xml = $(response.data);
              const location = $xml.find("Location").text();
              const key = $xml.find("Key").text();
              const index = this.files.findIndex((item) => item.id === id);

              if (index !== -1) {
                this.files[index]["key"] = key;
                this.files[index]["location"] = location;
                this.files[index]["url"] = location;
                this.files[index]["status"] = "success";
              }
            });
          });
      },
    });
  });
};
