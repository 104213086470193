function copyToClipboard(e) {
  var t = document.createElement("textarea");
  document.body.appendChild(t),
    (t.value = e),
    t.select(),
    document.execCommand("copy"),
    document.body.removeChild(t);
}

export default copyToClipboard;
